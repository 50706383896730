import {
     getDaily as getDailyAPI,
     getWelcome as getWelcomeAPI,
     freeGC as freeGCAPI,
     getReferralStats as getReferralStatsAPI,
     getBday as getBdayAPI
} from "api/bonus.js";
import { defineStore } from "pinia";
import eventBus from "plugins/event.js";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { subDays, addDays, isWithinInterval, format } from "date-fns";
import { useFormatter } from "composables/use-formatter.js";
import Cookies from "js-cookie";

export const bonusStore = defineStore("bonus", {
     state: () => {
          return {
               dailyBonus: {},
               welcomeBonus: null,
               claimable_daily: {},
               details: {},
               error_msg: null,
               referral_stats: null,
               bdayBonus: null
          };
     },
     actions: {
          getDaily(type) {
               return new Promise((resolve, reject) => {
                    getDailyAPI(type)
                         .then((response) => {
                              this.$patch((state) => {
                                   state.dailyBonus[type] = response[0];
                                   state.details[type] =
                                        state.dailyBonus[type] != null
                                             ? JSON.parse(
                                                    state.dailyBonus[type]
                                                         .details
                                               )
                                             : null;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          setClaimableDaily(status, type) {
               this.$patch((state) => {
                    state.claimable_daily[type] = status;
               });
          },
          getWelcome() {
               return new Promise((resolve, reject) => {
                    getWelcomeAPI()
                         .then((response) => {
                              if (response.length >= 1) {
                                   setTimeout(() => {
                                        this.setWelcomeBonus(
                                             response[0] != null
                                                  ? JSON.parse(
                                                         response[0].details
                                                    )
                                                  : null
                                        );

                                        eventBus.emit("open:welcomebonus");
                                   }, 3000);
                              } else {
                                   this.setWelcomeBonus(null);
                              }

                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   this.setWelcomeBonus(null);
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          setWelcomeBonus(data) {
               this.$patch((state) => {
                    state.welcomeBonus = data;
               });
          },
          claimFreeGC() {
               const useAuthStore = authStore();
               return new Promise((resolve, reject) => {
                    freeGCAPI()
                         .then(async () => {
                              eventBus.emit("open:free_gc_top");
                              await useAuthStore.reloadBalance({
                                   type: "gold_coins"
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          getReferralStats() {
               return new Promise((resolve, reject) => {
                    getReferralStatsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.referral_stats = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          getBday(bday) {
               if (Cookies.get("bday_bonus")) {
                    const filter = JSON.parse(Cookies.get("bday_bonus"));
                    this.$patch((state) => {
                         if (filter?.status == null) {
                              state.bdayBonus = filter["details"];
                              this.showBdayPopup(bday, filter);
                         }
                    });
                    return;
               }

               return new Promise((resolve, reject) => {
                    getBdayAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response.length >= 1) {
                                        setTimeout(() => {
                                             const filter = _.find(
                                                  response,
                                                  function (params) {
                                                       return (
                                                            params["type"] ==
                                                            "birthday"
                                                       );
                                                  }
                                             );

                                             if (filter) {
                                                  const now = new Date();
                                                  now.setTime(
                                                       now.getTime() +
                                                            1 * 60 * 60 * 1000
                                                  );

                                                  Cookies.set(
                                                       "bday_bonus",
                                                       JSON.stringify(filter),
                                                       { expires: now }
                                                  );

                                                  state.bdayBonus =
                                                       filter["details"];

                                                  this.showBdayPopup(
                                                       bday,
                                                       filter
                                                  );
                                             }
                                        }, 3000);
                                   } else {
                                        state.bdayBonus = null;

                                        const now = new Date();
                                        now.setTime(
                                             now.getTime() + 1 * 60 * 60 * 1000
                                        );

                                        Cookies.set(
                                             "bday_bonus",
                                             JSON.stringify(response),
                                             { expires: now }
                                        );
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          showBdayPopup(bday, filter) {
               const before = subDays(
                    new Date(bday),
                    filter.details.show_days_before
               );
               const after = addDays(
                    new Date(bday),
                    filter.details.show_days_after
               );

               const within = isWithinInterval(new Date(bday), {
                    start: before,
                    end: after
               });

               if (within) {
                    const { isMyBirthdayWithinTheRange } = useFormatter();

                    const match = isMyBirthdayWithinTheRange(
                         format(new Date(), "yyyy-MM-dd")
                    );

                    if (match) {
                         setTimeout(async () => {
                              eventBus.emit("open:bdaybonus");
                         }, 0);
                    }
               }
          }
     }
});
